<template>
  <svg
    class="fill-current h-10 w-10 text-gray-600"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 9.33333C16.0812 9.33333 17.4377 9.89524 18.4379 10.8954C19.4381 11.8956 20 13.2522 20 14.6667C20 16.0812 19.4381 17.4377 18.4379 18.4379C17.4377 19.4381 16.0812 20 14.6667 20C13.2522 20 11.8956 19.4381 10.8954 18.4379C9.89524 17.4377 9.33333 16.0812 9.33333 14.6667C9.33333 13.2522 9.89524 11.8956 10.8954 10.8954C11.8956 9.89524 13.2522 9.33333 14.6667 9.33333ZM2.73333 16H0V13.3333H2.73333C3.33333 7.77333 7.77333 3.33333 13.3333 2.73333V0H16V2.73333C21.56 3.33333 26 7.77333 26.6 13.3333H29.3333V16H26.6C26 21.56 21.56 26 16 26.6V29.3333H13.3333V26.6C7.77333 26 3.33333 21.56 2.73333 16ZM14.6667 5.33333C12.1913 5.33333 9.81734 6.31666 8.067 8.067C6.31666 9.81734 5.33333 12.1913 5.33333 14.6667C5.33333 17.142 6.31666 19.516 8.067 21.2663C9.81734 23.0167 12.1913 24 14.6667 24C17.142 24 19.516 23.0167 21.2663 21.2663C23.0167 19.516 24 17.142 24 14.6667C24 12.1913 23.0167 9.81734 21.2663 8.067C19.516 6.31666 17.142 5.33333 14.6667 5.33333Z"
    />
  </svg>
  <h2 class="mt-4 mb-10 font-bold text-4xl text-gray-700">
    {{ rightCard.caption }}
  </h2>
  <p class="text-gray-600 leading-loose mb-6">
    {{ rightCard.text }}
  </p>
</template>

<script>
export default {
  name: "rightCard",
  props: {
    rightCard: Object,
  },
  setup() {},
};
</script>
